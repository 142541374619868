import firebase from 'firebase'
import { Observable } from "rxjs";
import { dbRef } from '../../environment/firebase';


const doc = (path: string) => dbRef().doc(path);
const col = (path: string) => dbRef().collection(path);
const timestamp = () => firebase.firestore.FieldValue.serverTimestamp()


const getDoc = async <T>(path: string | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>) => {
    let _doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

    if (typeof path === 'string') {

        _doc = doc(path);
    } else {

        _doc = path;
    }

    const snap = await _doc.get();

    if (snap.exists) {

        return ({ id: snap.id, ...snap.data() } as any) as T;
    } else {

        return null;
    }
}

const getDocR = <T>(path: string | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>) => {
    let _doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;

    if (typeof path === 'string') {

        _doc = doc(path);
    } else {

        _doc = path;
    }

    return new Observable<T>((obs) => {
        return _doc.onSnapshot(
            (snap) => {
                const _data = ({ id: snap.id, ...snap.data() } as any) as T;
                obs.next(_data)
            },
            (err) => obs.error(err),
            () => obs.complete()
        );
    })

}


const getCol = async <T>(
    path: string | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
    qry?: (_col: firebase.firestore.Query<firebase.firestore.DocumentData>) => firebase.firestore.Query<firebase.firestore.DocumentData>

) => {

    let _col: firebase.firestore.Query<firebase.firestore.DocumentData>;

    if (typeof path === 'string') {

        _col = col(path);
    } else {

        _col = path;
    }

    if (qry) {
        _col = qry(_col)
    }

    const snaps = await _col.get();

    return snaps.docs.map((snap) => {
        return ({ id: snap.id, ...snap.data() } as any) as T;
    })

}

const getColR = <T>(
    path: string | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
    qry?: (_col: firebase.firestore.Query<firebase.firestore.DocumentData>) => firebase.firestore.Query<firebase.firestore.DocumentData>

) => {

    let _col: firebase.firestore.Query<firebase.firestore.DocumentData>;

    if (typeof path === 'string') {

        _col = col(path);
    } else {

        _col = path;
    }

    if (qry) {
        _col = qry(_col)
    }

    return new Observable<T[]>((obs) => {
        return _col.onSnapshot(
            (snaps) => {
                const _data = snaps.docs.map((snap) => {
                    return ({ id: snap.id, ...snap.data() } as any) as T;
                })
                obs.next(_data)
            },
            (err) => obs.error(err),
            () => obs.complete()
        );
    })

}



const add = <T>(path: string | firebase.firestore.CollectionReference<firebase.firestore.DocumentData>, data: T) => {

    let _col: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
    if (typeof path === 'string') {

        _col = col(path);
    } else {

        _col = path;
    }

    return _col.add({
        ...data,
        createdAt: timestamp(),
        updatedAt: timestamp()
    })

}

const update = <T>(path: string | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>, data: T) => {

    let _doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    if (typeof path === 'string') {

        _doc = doc(path);
    } else {

        _doc = path;
    }

    return _doc.update({
        ...data,
        updatedAt: timestamp()
    })

}

const set = <T>(path: string | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>, data: T) => {

    let _doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    if (typeof path === 'string') {

        _doc = doc(path);
    } else {

        _doc = path;
    }

    return _doc.set({
        ...data,
        createdAt: timestamp(),
        updatedAt: timestamp()
    })

}

const upsert = async <T>(path: string | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>, data: T) => {

    let _doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    if (typeof path === 'string') {

        _doc = doc(path);
    } else {

        _doc = path;
    }

    const snap = await _doc.get();

    if (snap.exists) {
        return update<T>(_doc, data);
    } else {
        return set<T>(_doc, data);
    }

}

const remove = async (path: string | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>) => {

    let _doc: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
    if (typeof path === 'string') {

        _doc = doc(path);
    } else {

        _doc = path;
    }

    return _doc.delete();

}



export const DatabaseService = {
    add,
    update,
    upsert,
    remove,
    getDoc,
    getDocR,
    getCol,
    getColR,
    timestamp,
    set
}
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ManageEntryComponent from '../../components/ManageEntry/ManageEntry.component'
import { AuthContext } from '../../contexts/auth.context';
import { useHistory } from 'react-router-dom';
import { AlertContext } from '../../contexts/alert.context';
import EntriesService from '../../services/entries/entries.service';
import { EntryStatus, EntryType, IEntry, IEvent, ISong } from '../../models/interfaces/Entries';
import AuthService from '../../services/auth/auth.service';
import { UserContext } from '../../contexts/user.context';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TagsContext } from '../../contexts/tags.context';


const eventTypeOptions = [
    { label: "Event", value: "event" },
    { label: "Song", value: "song" },
]

const intitialData = {
    name: '',
    description: '',
    location: '',
    type: EntryType.EVENT,
    artist: '',
    album: '',
    newsSource: '',
    day: undefined,
    month: undefined,
    year: new Date(),
    step: 0,
    tags: []
}

const validationSchema = yup.object().shape({
    name: yup.string().max(250).required(),
    description: yup.string().max(600).when("type", {
        is: EntryType.EVENT,
        then: yup.string().required()
    }),
    location: yup.string().max(250).when("type", {
        is: EntryType.EVENT,
        then: yup.string().required()
    }),
    type: yup.string().max(250).required(),
    artist: yup.string().max(250).when("type", {
        is: EntryType.SONG,
        then: yup.string().required()
    }),
    album: yup.string().max(250).when("type", {
        is: EntryType.SONG,
        then: yup.string().required()
    }),
    newsSource: yup.string().max(250).when("type", {
        is: EntryType.EVENT,
        then: yup.string()
    }),
    day: yup.number().max(31).min(1),
    month: yup.number().max(12).min(1),
    year: yup.date().required().max(new Date()),
    tags: yup.array()
})

export default function ManageEntryContainer({
    location,
    addEntry,
}) {

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const { user: authUser } = useContext(AuthContext);
    const user = useContext(UserContext);
    const alert = useContext(AlertContext)
    const history = useHistory();

    const {
        selectors: {
            getTags
        }
    } = useContext(TagsContext)

    const formik = useFormik({
        initialValues: intitialData,
        validationSchema,
        isInitialValid: searchParams.get('action') === 'edit',
        onSubmit: () => {

        }
    })

    const isTouched = useCallback((field) => !!formik.touched[field], [formik.touched])
    const getError = useCallback((field) => isTouched(field) ? formik.errors[field] || "" : "", [formik.errors, isTouched]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        (async () => {
            // Set the form data based on if the mode is edit
            if (searchParams.get('action') === 'edit') {

                const entry = await EntriesService.getEntry(searchParams.get("entry"))

                if (entry) {
                    const { title, date: { day, month, year }, type, ...rest } = entry
                    const { description = "", newsSource = "", location = "" } = rest as IEvent;
                    const { artist, album } = rest as ISong;
                    const tagsList = getTags() || [];
                    const data = {
                        name: title,
                        description,
                        location,
                        type,
                        artist: artist,
                        album: album,
                        newsSource: newsSource,
                        day: undefined,
                        month: undefined,
                        year: undefined,
                        step: 0,
                        tags: (rest.tags || []).map((id) => tagsList.find(({ id: _id }) => _id === id)?.name).filter((res) => !!res)
                    }

                    if (day) {
                        data.day = day;
                    }

                    if (month) {
                        data.month = month;
                    }

                    if (year) {
                        const current = new Date()
                        data.year = new Date(`${current.getMonth() + 1}/${current.getDate()}/${year}`);
                    }

                    formik.setValues(data);
                    formik.setTouched(Object.keys(data).reduce((acc, c) => ({ ...acc, [c]: true }), {}));
                }

            }

        })()

    }, [searchParams, getTags])

    useEffect(() => {

        setLoading(authUser === undefined)

    }, [authUser])

    const onHandleChange = (key: string, value) => {
        formik.setFieldTouched(key);
        formik.setFieldValue(key, value);
    }

    const handleModalClose = () => {
        history.replace(history.location.pathname);
        formik.resetForm();
    }

    const onAddPress = () => {
        history.push(history.location.pathname + "?action=create");
    }

    const onCancelPress = () => {
        handleModalClose();
    }

    const onSavePress = async () => {

        const isEdit = searchParams.get('action') === "edit";

        const {
            name: title,
            description,
            location,
            type,
            artist,
            album,
            newsSource,
            day = null,
            month = null,
            year,
            tags
        } = formik.values;

        let status = isEdit ? EntryStatus.UPDATED : EntryStatus.NEW;

        const { uid: creatorId } = AuthService.getCurrentUser();

        if (user.isAdmin) {
            status = EntryStatus.ACTIVE
        }

        let entry: IEntry = {
            title: title.trim().toLowerCase(),
            creatorId,
            type,
            status,
            date: {
                day,
                month,
                year: year.getFullYear().toString(),
                timestamp: new Date(`${month || 1}/${day || 1}/${year.getFullYear()}`)
            },
            tags: getTags().filter(({ name }) => tags.includes(name)).map(t => t.id)
        };

        if (type === EntryType.EVENT) {
            entry = {
                ...entry,
                description,
                location,
                newsSource
            } as IEvent
        } else {
            entry = {
                ...entry,
                artist: artist.trim().toLowerCase(),
                album,
            } as ISong
        }

        try {

            handleModalClose();

            if (isEdit) {
                const _entry = await EntriesService.updateEntry(searchParams.get('entry'), entry);
                alert.success({ message: `${_entry.title} has been updated!` })
                addEntry(_entry);
            } else {
                const _entry = await EntriesService.createEntry(entry);
                alert.success({ message: "Entry has been added!" })
                addEntry(_entry);
            }


        } catch (e) {
            console.log("the error ", e)
            alert.error({ message: e.message, title: 'HI' })
        }

    }

    const tags = useMemo(() => {
        return (getTags() || []).map((tag) => ({ name: tag.name, value: tag.id }))
    }, [getTags])

    return (
        <ManageEntryComponent
            data={formik.values}
            onChange={onHandleChange}
            onBlur={formik.setFieldTouched}
            open={!!searchParams.get("action")}
            isTouched={isTouched}
            getError={getError}
            handleClose={handleModalClose}
            onCancelPress={onCancelPress}
            onAddPress={onAddPress}
            onSavePress={onSavePress}
            isCreate={searchParams.get("action") === 'create'}
            eventTypeOptions={eventTypeOptions}
            loggedIn={!!authUser}
            loading={loading}
            isValid={formik.isValid && formik.dirty}
            tagList={tags}
            isAdmin={user?.isAdmin}
        />
    )
}

import React from 'react'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Stepper from '@material-ui/core/Stepper';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepTwoComponent from './StepTwo.component';
import StepOneComponent from './StepOne.component';
import AuthComponent from '../auth/Auth.component';
import { ITag } from '../../models/interfaces/Tags';
import { EntryType } from '../../models/interfaces/Entries';


const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        minWidth: 275,
        position: 'relative'
    },
    card: {
        position: 'relative',
        height: '100vh',
        overflowY: 'scroll',
        margin: "0 auto",
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        paddingTop: '3em',
        width: '100%',
        [theme.breakpoints.up("md")]: {
            paddingTop: '1em',
            height: 'auto',
            maxWidth: '70vw',
        }
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold'
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            padding: "10px 80px"
        }
    },
    cardActions: {
        [theme.breakpoints.up("md")]: {
            padding: "20px 0"
        }
    },
    fab: {
        position: 'fixed',
        right: "5%",
        bottom: "5%",
        [theme.breakpoints.up('md')]: {
            right: "5%",
            bottom: "10%",
        }
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: '-10px'
    },
    cancel: {
        position: 'absolute',
        right: "5%",
        top: "5%",
        zindex: 20
    }
}));


function getSteps() {
    return ["Name & type", 'Details'];
}


interface Props {
    loggedIn: boolean,
    loading: boolean,
    isCreate: boolean,
    eventTypeOptions: any[],
    onSavePress: () => void,
    onCancelPress: () => void,
    open: boolean,
    handleClose: () => void,
    onAddPress: () => void,
    onChange: (...args) => void,
    isTouched: (a: string) => boolean,
    getError: (a: string) => boolean,
    onBlur: (...args) => void,
    isValid: boolean,
    tagList: any    [],
    isAdmin: boolean,
    data: {
        name: string,
        description: string,
        type: EntryType,
        day: any,
        month: any,
        year: Date,
        location: string,
        step: number,
        artist: string,
        album: string,
        newsSource: string,
        tags: ITag[]
    }
}


export default React.memo<Props>(function ManageEntryComponent({
    loggedIn,
    loading,
    isCreate,
    eventTypeOptions,
    onSavePress,
    onCancelPress,
    open,
    handleClose,
    onAddPress,
    onChange,
    isTouched,
    getError,
    onBlur,
    isValid,
    tagList,
    isAdmin,
    data: {
        name,
        description,
        type,
        day,
        month,
        year,
        location,
        step,
        artist,
        album,
        newsSource,
        tags
    }
}) {

    const classes = useStyles();
    const steps = getSteps();

    const onContainerClick = (e) => {
        if ((e.target as HTMLElement).matches("#container_backdrop")) {
            handleClose();
        }
    }

    let stepContent = (
        <StepOneComponent
            onBlur={onBlur}
            isTouched={isTouched}
            getError={getError}
            eventTypeOptions={eventTypeOptions}
            onChange={onChange}
            name={name}
            type={type}
            isCreate={isCreate}
        />)

    if (step === 1) {
        stepContent = (
            <StepTwoComponent
                getError={getError}
                onChange={onChange}
                description={description}
                location={location}
                date={{ day, month, year }}
                type={type}
                artist={artist}
                album={album}
                newsSource={newsSource}
                onBlur={onBlur}
                tagList={tagList}
                tags={tags}
                isAdmin={isAdmin}
            />
        )


    }

    let view = (
        <Card className={classes.card}>
            <div className={classes.cancel}>
                <Fab onClick={onCancelPress} color="secondary" size="small" aria-label="add">
                    <CancelIcon />
                </Fab>
            </div>
            <Typography component="h2" className={classes.title}>
                {isCreate ? "Add New Entry" : "Edit Entry"}
            </Typography>
            <CardContent className={classes.cardContent}>
                <Stepper activeStep={step} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {stepContent}

            </CardContent>
            <CardActions className={classes.cardActions}>
                {step === 1 ?
                    <>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={onChange.bind(this, 'step', step - 1)}
                        >
                            Back
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSavePress}
                            disabled={!isValid}
                        >
                            Save
                        </Button>
                    </>
                    :
                    null

                }

                {step === 0 ?
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onChange.bind(this, 'step', step + 1)}
                        disabled={!isTouched('name') || !!getError("name")}
                    >
                        Next
                    </Button>
                    :
                    null
                }
            </CardActions>
        </Card>
    )


    if (!loggedIn) {
        view = (
            <AuthComponent />
        )
    }

    if (loading) {
        view = (
            <CircularProgress className={classes.progress} />
        )
    }


    return (
        <>
            <div className={classes.fab}>
                <Fab onClick={onAddPress} color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.container} id="container_backdrop" onClick={onContainerClick}>
                    {view}
                </div>
            </Modal>
        </>
    )
})

import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import TimelineIcon from '@material-ui/icons/Timeline';
import Divider from '@material-ui/core/Divider';
import InfoIcon from '@material-ui/icons/Info';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Link } from 'react-router-dom';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const spotifyLink = "https://open.spotify.com/playlist/7cPikToQg0Ulc29aymLEWn?si=O6nchigJTH6AVspxJO6Thw&utm_source=copy-link"
const youtubeMusicLink = "https://music.youtube.com/playlist?list=PL6Us0t0R-opJfC1D156eJLUwqIw7sHCtf&feature=share"


export default function SideMenuComponent({
    onCloseClicked,
    onLogoutPress,
    onDeleteAccountPress,
    isAuth,
    isAdmin
}) {

    return (
        <>
            <List
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Navigate
                    </ListSubheader>
                }>
                <ListItem button component={Link} to="/" onClick={onCloseClicked}>
                    <ListItemIcon><TimelineIcon /></ListItemIcon>
                    <ListItemText primary='Timeline' />
                </ListItem>
                <ListItem button component={Link} to="/about" onClick={onCloseClicked}>
                    <ListItemIcon><InfoIcon /></ListItemIcon>
                    <ListItemText primary='About' />
                </ListItem>
                {isAuth && isAdmin ?
                    <ListItem button component={Link} to="/tags" onClick={onCloseClicked}>
                        <ListItemIcon><LocalOfferIcon /></ListItemIcon>
                        <ListItemText primary='Tags' />
                    </ListItem>
                    :
                    null
                }

            </List>
            <Divider />

            <Divider />
            <List
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                        Connect
                    </ListSubheader>
                }
            >
                <ListItem button component='a' href="https://www.facebook.com/wevebeenprotesting" target="_blank">
                    <ListItemIcon><FacebookIcon /></ListItemIcon>
                    <ListItemText primary='Facebook' />
                </ListItem>
                <ListItem button component='a' href="https://twitter.com/beenprotesting" target="_blank">
                    <ListItemIcon><TwitterIcon /></ListItemIcon>
                    <ListItemText primary='Twitter' />
                </ListItem>
                <ListItem button component='a' href="https://www.instagram.com/wevebeenprotesting/" target="_blank">
                    <ListItemIcon><InstagramIcon /></ListItemIcon>
                    <ListItemText primary='Instagram' />
                </ListItem>
                <ListItem button component='a' href="https://support.wevebeenprotesting.com/" target="_blank">
                    <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
                    <ListItemText primary='Shop' />
                </ListItem>
                <ListItem button component='a' href={spotifyLink} target="_blank">
                    <ListItemIcon><MusicNoteIcon /></ListItemIcon>
                    <ListItemText primary='Spotify' />
                </ListItem>
                <ListItem button component='a' href={youtubeMusicLink} target="_blank">
                    <ListItemIcon><YouTubeIcon /></ListItemIcon>
                    <ListItemText primary='YouTube Music' />
                </ListItem>
            </List>

            <Divider />

            {isAuth ?
                <List
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            My Accoount
                        </ListSubheader>
                    }
                >
                    <ListItem button onClick={() => {
                        onLogoutPress()
                        onCloseClicked()
                    }} component='a' href="#" >
                        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                        <ListItemText primary='Logout' />
                    </ListItem>
                    <ListItem button
                        onClick={onDeleteAccountPress}
                        component='a' href="#">
                        <ListItemIcon color="danger"><DeleteForeverIcon color="error" /></ListItemIcon>
                        <ListItemText color="primary" primary='Delete Account' />
                    </ListItem>

                </List>
                : null
            }


        </>
    )
}

import React from 'react';
import About from './components/about';
import LayoutComponent from './components/Layouts/Layout.component';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import TimelineContainer from './containers/Timeline/Timeline.container';
import AuthContextProvider from './contexts/auth.context';
import UserContextProvider from './contexts/user.context';
import FiltersContextProvider from './contexts/filters.context';
import EmailVerificationContainer from './containers/Auth/EmailVerification.container';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import EntriesContextProvider from './contexts/entries.context';
import ConfirmContextProvider from './contexts/confirm.context';
import AggrigatesContextProvider from './contexts/aggrigates';
import TagsContainer from './containers/Tags/Tags.container';
import TagsContextProvider from './contexts/tags.context';

const darkTheme = createMuiTheme({
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h2',
        h2: 'h2',
        h3: 'h2',
        h4: 'h2',
        h5: 'h2',
        h6: 'h2',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'span',
      },
    },
  },
  palette: {
    type: 'dark',
    primary: {
      main: "#115293"
    },
    secondary: {
      main: "#9a0036"
    },
    success: {
      main: "#28C94D"
    },
    warning: {
      main: "#F40C1A"
    }
  },
});



export default function App() {
  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AuthContextProvider>
            <UserContextProvider>
              <EntriesContextProvider>
                <TagsContextProvider>
                  <ConfirmContextProvider>
                    <FiltersContextProvider>
                      <AggrigatesContextProvider>
                        <LayoutComponent>
                          <Switch>
                            <Route path="/about" component={About} />
                            <Route path="/tags" component={TagsContainer} />
                            <Route path="/timeline" component={TimelineContainer} />
                            <Route path="/email-verify" component={EmailVerificationContainer} />
                            <Redirect path="/" to="/timeline" exact />
                          </Switch>
                        </LayoutComponent>
                      </AggrigatesContextProvider>
                    </FiltersContextProvider>
                  </ConfirmContextProvider>
                </TagsContextProvider>
              </EntriesContextProvider>
            </UserContextProvider>
          </AuthContextProvider>

        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Router>
  )
}
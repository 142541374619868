import React, { useContext, useEffect, useMemo, useState } from "react";
import { IUser } from "../models/interfaces/User";
import UserService from "../services/user/user.service";
import { AuthContext } from "./auth.context";

export const UserContext = React.createContext<IUser>(null);


const UserContextProvider = ({
    children
}) => {

    const [user, setUser] = useState<IUser>(null);
    const { user: authUser } = useContext(AuthContext);

    useEffect(() => {

        if (authUser) {

             UserService.getUser(authUser.uid)
                .then(setUser)


            // return sub.unsubscribe

        } else {

            setUser(null);
        }

    }, [authUser])

    const Children = useMemo(() => children, [])

    return (
        <UserContext.Provider value={user}>
            {Children}
        </UserContext.Provider>
    )
}


export default UserContextProvider
import React from 'react'
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import { classNames } from '../../utils/utils';


const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        minWidth: 275,
        position: 'relative'
    },
    card: {
        position: 'relative',
        height: '100vh',
        overflowY: 'scroll',
        margin: "0 auto",
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        paddingTop: '3em',
        width: '100%',
        [theme.breakpoints.up("md")]: {
            paddingTop: '1em',
            height: 'auto',
            maxWidth: '70vw',
        }
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold'
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        [theme.breakpoints.up("md")]: {
            padding: "10px 80px"
        }
    },
    cardActions: {
        [theme.breakpoints.up("md")]: {
            padding: "20px 0"
        }
    },
    fab: {
        position: 'fixed',
        right: "5%",
        bottom: "5%",
        [theme.breakpoints.up('md')]: {
            right: "5%",
            bottom: "10%",
        }
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginLeft: '-10px'
    },
    cancel: {
        position: 'absolute',
        right: "5%",
        top: "5%",
        zindex: 20
    },
    textField: {
        margin: "0 auto",
    },
    item: {
        marginBottom: "30px"
    }
}));



export default function ManageTagComponent({
    isCreate,
    open,
    handleClose,
    onChange,
    onSavePress,
    onBlur,
    getError,
    isValid,
    name
}) {

    const classes = useStyles();


    let view = (
        <Card className={classes.card}>
            <div className={classes.cancel}>
                <Fab onClick={handleClose} color="secondary" size="small" aria-label="add">
                    <CancelIcon />
                </Fab>
            </div>
            <Typography component="h2" className={classes.title}>
                {isCreate ? "Add Tag" : "Edit Tag"}
            </Typography>
            <CardContent className={classes.cardContent}>

                <TextField
                    fullWidth
                    className={classNames(classes.textField, classes.item)}
                    id="filled-basic"
                    label="Name"
                    variant="outlined"
                    value={name}
                    onChange={(e) => onChange("name", e.target.value)}
                    error={getError('name')}
                    helperText={getError('name')}
                    onBlur={onBlur.bind(this, "name")}
                />

            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleClose}
                >
                    Back
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSavePress}
                    disabled={!isValid}
                >
                    Save
                </Button>

            </CardActions>
        </Card>
    )




    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <div className={classes.container}>
                {view}
            </div>
        </Modal>

    )
}

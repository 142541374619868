import { setDate } from 'date-fns';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { ConfirmContext } from '../../contexts/confirm.context';
import DrawerContext from '../../contexts/drawer.context';
import { EntriesContext } from '../../contexts/entries.context';
import { FiltersContext } from '../../contexts/filters.context';
import { TagsContext } from '../../contexts/tags.context';
import { UserContext } from '../../contexts/user.context';
import { EntryStatus, IEntry } from '../../models/interfaces/Entries';
import TimelinePage from '../../pages/Timeline/Timeline.page'
import EntriesService from '../../services/entries/entries.service';
import { sortEntries } from '../../utils/utils';
import ManageEntryContainer from './ManageEntry.container';


export default function TimelineContainer(props) {

    const {
        actions: {
            setEntries,
            addEntry,
            updateEntry,
            removeEntry,
            setDates
        },
        selectors: {
            getEntries
        }
    } = useContext(EntriesContext)


    const {
        selectors: {
            getTag
        }
    } = useContext(TagsContext)


    const entries = useMemo(getEntries, [getEntries]) ;

    const { filters } = useContext(FiltersContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [filteredEntries, setFilteredEntries] = useState<IEntry[]>([])

    const confirm = useContext(ConfirmContext)

    const user = useContext(UserContext)
    const history = useHistory();

    const ownerRole = 'owner';
    const adminRole = 'admin';

    const determinRole = useCallback((item) => {
        if (!user) return "";
        return (user.isAdmin) ? adminRole : item.creatorId === user.id ? ownerRole : ""
    }, [user])

    const {
        setOpen: setDrawerOpen,
        open: drawerOpen
    } = useContext(DrawerContext);


    useEffect(() => {
        EntriesService.getEntries()
            .then(setEntries)
            .finally(() => setLoading(false))

    }, [])

    useEffect(() => {
        EntriesService.getTimelineDecades()
        .then(setDates)
    }, [])


    useEffect(() => {

        if (filters) {
            setLoading(true)
            EntriesService.getEntriesByFilter(filters)
                .then((e) => {
                    setFilteredEntries(sortEntries(e))
                    setLoading(false)
                })
        } else {

            setFilteredEntries([])
        }

    }, [filters]);





    const onFilterPress = () => {
        setDrawerOpen({ content: "filters", open: true })
    }

    const onEditPress = (entry) => {
        history.push(history.location.pathname + `?action=edit&entry=${entry.id}`);
    }

    const onDeletePress = (entry) => {
        confirm.show({
            title: `You are about to delete ${entry.title}`,
            message: user.isAdmin ? "This entry will be permanently removed" : `The entry will be removed from the timeline, pending the approval of an admin`,
            actions: [
                {
                    title: "Yes",
                    role: 'destructive',
                    handler: () => {

                        if (user?.isAdmin) {
                            EntriesService.deleteEntry(entry.id)

                        } else {
                            entry.status = EntryStatus.DELETED
                            EntriesService.updateEntry(entry.id, entry)
                        }

                        removeEntry(entry);
                    }
                }
            ]
        })
    }

    const onUndoDeletePress = (entry) => {
        if (!user.isAdmin) return;
        confirm.show({
            title: `You are about undo the delete of ${entry.title}`,
            message: `The entry will return to the timeline`,
            actions: [
                {
                    title: "Yes",
                    handler: () => {
                        entry.status = EntryStatus.ACTIVE;
                        EntriesService.updateEntry(entry.id, entry);
                        updateEntry(entry)
                    }
                }
            ]
        })
    }

    const onApprovePress = async (entry) => {
        entry.status = EntryStatus.ACTIVE
        EntriesService.updateEntry(entry.id, entry)
        updateEntry(entry)
    }

    const entries_ = useMemo(() => {
        let ret = filters ? filteredEntries : entries;
       

        return ret.map((entry) => {

            let tags = []
            if(entry.tags && user?.isAdmin) {
                tags = entry?.tags?.map((id) => getTag(id)?.name);
            }

            return {...entry, tags}
        })
    }, [filteredEntries, entries, getTag, user])

   
    return (
        <>
            <TimelinePage
                entries={entries_}
                loading={loading}
                onFilterPress={onFilterPress}
                drawerOpen={drawerOpen}
                user={user}
                onEditPress={onEditPress}
                onDeletePress={onDeletePress}
                onUndoDeletePress={onUndoDeletePress}
                onApprovePress={onApprovePress}
                determinRole={determinRole}
            />

            <ManageEntryContainer
                {...props}
                isAdmin={true}
                addEntry={addEntry}
            />

        </>
    )
}

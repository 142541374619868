import firebase from "firebase/app"
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import AggrigatesService from "../services/aggrigates/aggrigates.service";
import AuthService from "../services/auth/auth.service";


type User = firebase.User

export const AggrigatesContext = React.createContext<{
    titles: string[],
    artists: string[]
}>({
    titles: [],
    artists: []
});


const AggrigatesContextProvider = ({
    children
}) => {

    const [agg, setAgg] = useState<{
        artists: string[],
        titles: string[]
    }>({
        titles: [],
        artists: []
    });


    useEffect(() => {
        AggrigatesService.getAggrigates()
            .then(({ artists, titles }) => {

                setAgg({
                    artists: Object.keys(artists).sort(),
                    titles: Object.keys(titles).sort()
                })

            })
    }, [])


    const Children = useMemo(() => children, [])

    return (
        <AggrigatesContext.Provider value={agg}>
            {Children}
        </AggrigatesContext.Provider>
    )
}


export default AggrigatesContextProvider
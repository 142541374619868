import React, { useCallback } from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import EventIcon from '@material-ui/icons/Event';
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import 'react-vertical-timeline-component/style.min.css';
import { EntryStatus, EntryType, IEntry, IEvent, ISong } from '../../models/interfaces/Entries';
import AppChip from '../../widgets/AppChip';
import { classNames, titleCase } from '../../utils/utils';


const useStyles = makeStyles((theme) => ({
    itemAction: {
        margin: '0 5px'
    },
    actionsContainer: {
        position: 'absolute',
        right: 1,
        top: 0,
        minWidth: '100px',
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: "end"
    },
    chip: {
        marginTop: '-10px',
        [theme.breakpoints.up('md')]: {
            marginTop: '-17px',
            marginBottom: "5px"
        }
    },
    capatilize: {
        textTransform: "capitalize"
    },
    tags: {
        fontSize: "11px !important",
        [theme.breakpoints.up('md')]: {
            fontSize: "13px !important",
        }
    },
    tagsContainer: {
        display: 'flex',
        width: '100%',
        textOverflow: 'wrap'
    },
    dateClassName: {
        marginTop: 0
    }
}));

const Styles = () => ({
    musicContentStyle: {
        background: '#115293',
        color: '#fff',
        borderTop: '5px solid #115293',
        position: 'relative',
    },
    eventContentStyle: {
        background: '#9a0036',
        color: '#fff',
        borderTop: '5px solid #9a0036',
        position: 'relative',
    },
    musicContentArrowStyle: {
        borderRight: '7px solid #115293'
    },
    eventContentArrowStyle: {
        borderRight: '7px solid #9a0036'
    },
    musicIconStyle: {
        background: '#115293',
        color: '#fff'
    },
    eventIconStyle: {
        background: '#9a0036',
        color: '#fff'
    },

});


interface Props {
    entry: IEntry,
    role: string,
    onEditPress: (entry: IEntry) => void,
    onDeletePress: (entry: IEntry) => void,
    onUndoDeletePress: (entry: IEntry) => void,
    onApprovePress: (entry: IEntry) => void
}


const TimelineElement = ({
    entry,
    role,
    onEditPress,
    onDeletePress,
    onUndoDeletePress,
    onApprovePress,
}: Props) => {

    const styles = Styles();
    const classes = useStyles();
    // const { open: drawerOpen } = useContext(DrawerContext)
    const [anchorActions, setAnchorActions] = React.useState<null | HTMLElement>(null);

    const date = () => {
        const { day, month, year } = entry.date
        let str = "";

        if (month) {
            str = str + `${month}/`;
        }

        if (day) {
            str = str + day + "/";
        }



        return str + year;
    }

    const onCloseMenu = () => {
        setAnchorActions(null);
    }

    const onActionPress = (action) => {
        switch (action) {
            case 'edit':
                onEditPress(entry)
                break
            case 'delete':
                onDeletePress(entry)
                break
            case 'undo_delete':
                onUndoDeletePress(entry)
                break;
            case 'approve':
                onApprovePress(entry)
                break;

        }
        onCloseMenu()
    }



    let actions = useCallback(() => {
        if (!role) return null;
        return (
            <div className={classes.actionsContainer}>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorActions}
                    keepMounted
                    open={Boolean(anchorActions)}
                    onClose={onCloseMenu}
                >
                    {(role === "admin" || role === "owner") ?
                        <div>
                            <MenuItem onClick={onActionPress.bind(this, 'edit')}>
                                Edit
                            </MenuItem>
                            <MenuItem onClick={onActionPress.bind(this, 'delete')}>
                                Delete {role === "admin" && "Permanently"}
                            </MenuItem>
                        </div>
                        : null
                    }

                    {role === "admin" && entry.status === EntryStatus.DELETED ?
                        <MenuItem onClick={onActionPress.bind(this, 'undo_delete')}>
                            Undo Delete
                        </MenuItem>
                        :
                        null
                    }

                    {role === "admin" && entry.status === EntryStatus.NEW || entry.status === EntryStatus.UPDATED ?
                        <>
                            <MenuItem onClick={onActionPress.bind(this, 'approve')}>
                                Approve
                            </MenuItem>
                        </>
                        :
                        null
                    }

                </Menu>
                <MoreVertIcon
                    onClick={(e: any) => setAnchorActions(e.currentTarget)}
                    style={{ cursor: 'pointer' }}
                />
            </div>

        )
    }, [role, entry, anchorActions]);

    let element = null
    let color: string = ""
    switch (entry.status) {
        case EntryStatus.NEW:
            color = "success"
            break;
        case EntryStatus.DELETED:
            color = "danger"
            break;
        case EntryStatus.UPDATED:
            color = "success"
            break;
    }

    const tags = entry?.tags?.length  ? (
        <div className={classes.tagsContainer}>
            <p className={classes.tags}>Tags: {entry.tags.join(", ")}</p>
        </div>
    ) : null

    if (entry.type === EntryType.SONG) {
        const song = entry as ISong;
        const album = () => { if (song.album) { return <p>Album: {song.album} </p> } };

        element = (
            <VerticalTimelineElement
                contentStyle={styles.musicContentStyle as any}
                contentArrowStyle={styles.musicContentArrowStyle}
                date={date()}
                iconStyle={styles.musicIconStyle}
                icon={<MusicNoteIcon />}
                dateClassName={classes.dateClassName}
            >

                {role === 'admin' && song.status !== EntryStatus.ACTIVE ? (
                    <div className={classes.chip}>
                        <AppChip color={color as any}>
                            {song.status}
                        </AppChip>
                    </div>
                ) : null}

                <h3 className={classNames("vertical-timeline-element-title", classes.capatilize)}>{titleCase(song.title)}</h3>
                <p className={classes.capatilize}>Artist: {titleCase(song.artist)}</p>
                {album()}
                {actions()}
                {tags}
            </VerticalTimelineElement>
        );
    } else if (entry.type === EntryType.EVENT) {
        const event = entry as IEvent;
        element = (
            <VerticalTimelineElement
                contentStyle={styles.eventContentStyle as any}
                contentArrowStyle={styles.eventContentArrowStyle}
                date={date()}
                iconStyle={styles.eventIconStyle}
                icon={<EventIcon />}
                dateClassName={classes.dateClassName}
            >


                {role === 'admin' && event.status !== EntryStatus.ACTIVE ? (
                    <div className={classes.chip}>
                        <AppChip color={color as any}>
                            {event.status}
                        </AppChip>
                    </div>

                ) : null}
                <h3 className={classNames("vertical-timeline-element-title", classes.capatilize)}>{event.title}</h3>
                <p>{event.description}</p>
                {actions()}
                {tags}
            </VerticalTimelineElement>
        );
    }

    return element


}

export default React.memo(TimelineElement);
const getUsers = () => {
    return 'users'
}
const getEntries = () => {
    return 'entries'
}
const getUser = (id: string) => {
    return `${getUsers()}/${id}`
}

const getEntry = (id: string) => {
    return `${getEntries()}/${id}`
}

const getTags = () => {
    return 'tags'
}


const getTag = (id: string) => {
    return getTags() + "/" + id
}



export const DB_COLLECTIONS = {
    getUser,
    getUsers,
    getEntries,
    getEntry,
    getTags,
    getTag
}
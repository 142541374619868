import React from 'react'
import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchBar from "material-ui-search-bar";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CreateIcon from '@material-ui/icons/Create';
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        right: "5%",
        bottom: "5%",
        [theme.breakpoints.up('md')]: {
            right: "5%",
            bottom: "10%",
        }
    },
    container: {
        overflowX: 'scroll',
        maxWidth: "800px",
        margin: "0 auto"
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    progress: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginLeft: '-10px'
    },

}));


export default function TagsPage({
    searchTxt,
    setSearchTxt,
    onAddPress,
    onEditPress,
    onDeletePress,
    tags
}) {
    const classes = useStyles();

    if (tags === undefined) {
        return <CircularProgress className={classes.progress} />
    }

    return (
        <>
            <Card className={classes.container}>
                <SearchBar
                    value={searchTxt}
                    onChange={setSearchTxt}
                />
                <CardContent>
                    <List>
                        {tags.map((tag) => (
                            <ListItem key={tag.id}>
                                <ListItemText
                                    primary={tag.name}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton onClick={() => onEditPress(tag)} edge="end" aria-label="delete">
                                        <CreateIcon />
                                    </IconButton>
                                    <IconButton onClick={() => onDeletePress(tag)} edge="end" aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>

                </CardContent>

            </Card>
            <div className={classes.fab}>
                <Fab onClick={onAddPress} color="primary" aria-label="add">
                    <AddIcon />
                </Fab>
            </div>
        </>
    )
}

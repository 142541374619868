import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { AuthContext } from '../../contexts/auth.context'
import EmailVerificationPage from '../../pages/Auth/EmailVerification.page'
import AuthService from '../../services/auth/auth.service';

export default function EmailVerificationContainer() {

    const { user } = useContext(AuthContext);
    const [emailSent, setEmailSent] = useState(false)
    const [loading, setLoading] = useState(false)
    const history= useHistory()

    useEffect(() => {
        if(user && user.emailVerified) {
            history.replace("/")
        }
    }, [user])

    const onSendVerificationPress = () => {
        setLoading(true)

        AuthService.sendVerificationEmail()
            .then(() => {
                setEmailSent(true)
            }).finally(() => {
                setLoading(false);
            })

    }

    const onRefreshPress = () => {
        window.location.reload();
    }


    return (
        <EmailVerificationPage
            loading={loading}
            email={user?.email}
            emailSent={emailSent}
            onSendVerificationPress={onSendVerificationPress}
            onRefreshPress={onRefreshPress}
        />
    )
}

import { DB_COLLECTIONS } from "../../constants/database";
import { IUser } from "../../models/interfaces/User";
import AuthService from "../auth/auth.service";
import { DatabaseService } from "../database/database.service";


const getUser = async (id: string): Promise<IUser> => {
    return DatabaseService.getDoc(DB_COLLECTIONS.getUser(id))
}

const getUserR = (id: string) => {
    return DatabaseService.getDocR<IUser>(DB_COLLECTIONS.getUser(id))
}

const deleteUser = async (id) => {
    await DatabaseService.remove(DB_COLLECTIONS.getUser(id));
    await AuthService.deleteUser()
}


const UserService = {
    getUser,
    deleteUser,
    getUserR
}

export default UserService;
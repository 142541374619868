import React, { useCallback, useState } from "react";
import { IEntriesFilter } from '../models/interfaces/Entries'


const defaultFilters: IEntriesFilter = {
    from: null,
    to: null,
    artist: "",
    songName: "",
    createdBy: "",
    pendingApproval: false,
    tags: [],
    decade: (new Date()).getFullYear()
}


export const FiltersContext = React.createContext<{
    filters: IEntriesFilter,
  
    applyFilters: (f: IEntriesFilter) => void
}>({

    filters: defaultFilters,
    applyFilters: () => { },
});


const FiltersContextProvider = ({
    children
}) => {

    const [filters, applyFilters] = useState<IEntriesFilter>(null);



    return (
        <FiltersContext.Provider value={{
            filters,
            applyFilters,
        }}>
            {children}
        </FiltersContext.Provider>
    )
}


export default FiltersContextProvider
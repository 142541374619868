import firebase from "firebase/app"
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import AuthService from "../services/auth/auth.service";


type User = firebase.User

export const AuthContext = React.createContext<{
    user: User,
    logout: () => void
}>({
    user: null,
    logout: null
});


const AuthContextProvider = ({
    children
}) => {

    const [user, setUser] = useState<User>();
    const history = useHistory();


    useEffect(() => {
        return AuthService.authStatus(setUser)
    }, [])

    useEffect(() => {
        if(user && !user.emailVerified) {
            history.replace("/email-verify");
        }
    }, [user])

    const Children = useMemo(() => children, [])

    return (
        <AuthContext.Provider value={{ user, logout: AuthService.logout }}>
            {Children}
        </AuthContext.Provider>
    )
}


export default AuthContextProvider
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { classNames } from '../../../utils/utils';

import Logo from '../../../../assets/img/wbp-logo.png';

const fbLink = "https://www.facebook.com/wevebeenprotesting";
const twLink = "https://twitter.com/beenprotesting"
const igLink = "https://www.instagram.com/wevebeenprotesting/"
const storeLink = "https://support.wevebeenprotesting.com/"
const spotifyLink = "https://open.spotify.com/playlist/7cPikToQg0Ulc29aymLEWn?si=O6nchigJTH6AVspxJO6Thw&utm_source=copy-link"
const youtubeMusicLink = "https://music.youtube.com/playlist?list=PL6Us0t0R-opJfC1D156eJLUwqIw7sHCtf&feature=share"

const useStyles = makeStyles((theme) => ({
    appBar: {
        // zIndex: theme.zIndex.drawer + 1000,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        // zIndex: theme.zIndex.drawer + 1000,
    },
    navbarContainer: {
        display: `flex`,
        width: "100%",
    },
    linkText: {
        textDecoration: `none`,
        textTransform: `uppercase`,
        color: 'white',
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("md")]: {
            display: 'none'
        }
    },
    titleContainer: {
        cursor: 'pointer',
        [theme.breakpoints.up('md')]: {
            flex: 0.9,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }
    },
    navLinks: {
        display: `flex`,
        marginLeft: 'auto'
    },
    logo: {
        maxWidth: 260,
        [theme.breakpoints.up('md')]: {
            maxWidth: 300
        }
    }
}));

export default function NavbarComponent({
    setMenuOpen,
    open,
    isAuth,
    isAdmin,
    onLogoutPress,
    onDeleteAccountPress,
}) {

    const [anchorElConn, setAnchorElConn] = React.useState<null | HTMLElement>(null);
    const [anchorElLog, setAnchorElLog] = React.useState<null | HTMLElement>(null);
    const [anchorElAdmin, setAnchorElAdmin] = React.useState<null | HTMLElement>(null);


    const classes = useStyles();
    const history = useHistory();

    const handleClickConn = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElConn(event.currentTarget);
    };

    const handleCloseConn = () => {
        setAnchorElConn(null);
    };

    const handleClickLog = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElLog(event.currentTarget);
    };

    const handleCloseLog = () => {
        setAnchorElLog(null);
    };


    const handleClickAdmin = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElAdmin(event.currentTarget);
    };

    const handleCloseAdmin = () => {
        setAnchorElAdmin(null);
    };

    const onTitleClick = () => {
        history.replace("/")
    }

    const toggleNavDrawer = () => {
        if (open) {
            setMenuOpen(false);
        } else {
            setMenuOpen(true);
        }
    }

    const closeMenu = () => {
        setMenuOpen(false);
    }

    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
            })}
            style={{ background: '#000' }}
        >
            <Toolbar>
                <div className={classes.navbarContainer}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleNavDrawer}
                        edge="start"
                        className={clsx(classes.menuButton)}
                    >
                        <MenuIcon />
                    </IconButton>

                    <div onClick={onTitleClick} className={classes.titleContainer}>
                        <img className={classes.logo} src={Logo} />
                    </div>


                    <List
                        component="ul"
                        aria-labelledby="main navigation"
                        className={classes.navLinks}
                    >

                        <a
                            onClick={() => {
                                closeMenu();
                                history.push("/timeline");
                            }}
                            className={classes.linkText}>
                            <ListItem button>
                                <ListItemText primary="Timeline" />
                            </ListItem>
                        </a>

                        <a onClick={() => {
                            closeMenu();
                            history.push("/about");
                        }} className={classes.linkText}>
                            <ListItem button>
                                <ListItemText primary="About" />
                            </ListItem>
                        </a>

                        {isAuth && isAdmin ?
                                <a onClick={() => {
                                    history.push("/tags");
                                    handleCloseConn();
                                }} className={classes.linkText}>
                                    <ListItem button>
                                        <ListItemText primary="Tags" />
                                    </ListItem>
                                </a>
                            : null
                        }


                        <>
                            <Menu
                                id="simple-menu"
                                anchorEl={anchorElConn}
                                keepMounted
                                open={Boolean(anchorElConn)}
                                onClose={handleCloseConn}
                            >
                                <MenuItem onClick={() => {
                                    window.open(fbLink, "blank")
                                    handleCloseConn();
                                }}>
                                    Facebook
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    window.open(twLink, "blank")
                                    handleCloseConn();
                                }}>
                                    Twitter
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    window.open(igLink, "blank")
                                    handleCloseConn();
                                }}>
                                    Instagram
                                </MenuItem>
                                <MenuItem onClick={() => {
                                    window.open(storeLink, "blank")
                                    handleCloseConn();
                                }}>
                                    Shop
                                </MenuItem>

                                <MenuItem onClick={() => {
                                    window.open(spotifyLink, "blank")
                                    handleCloseConn();
                                }}>
                                    Spotify
                                </MenuItem>

                                <MenuItem onClick={() => {
                                    window.open(youtubeMusicLink, "blank")
                                    handleCloseConn();
                                }}>
                                    YouTube Music
                                </MenuItem>
                            </Menu>

                            <Button
                                className={classNames(classes.linkText)}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClickConn}
                            >
                                Connect
                            </Button>

                        </>



                        {isAuth &&
                            <>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorElLog}
                                    keepMounted
                                    open={Boolean(anchorElLog)}
                                    onClose={handleCloseLog}
                                // className={classes.connectMenu}
                                >
                                    <MenuItem onClick={() => {
                                        handleCloseLog()
                                        onLogoutPress()
                                    }}>
                                        <ListItemIcon>
                                            <ExitToAppIcon fontSize="small" />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        handleCloseLog();
                                        onDeleteAccountPress();
                                    }}>
                                        <ListItemIcon>
                                            <DeleteForeverIcon color="error" fontSize="small" />
                                        </ListItemIcon>
                                        Delete Account
                                    </MenuItem>
                                </Menu>

                                <Button
                                    className={classNames(classes.linkText)}
                                    aria-controls="simple-menu"
                                    aria-haspopup="true"
                                    onClick={handleClickLog}
                                >
                                    My Account
                                </Button>

                            </>
                        }
                    </List>
                </div>
            </Toolbar>
        </AppBar>
    )
}

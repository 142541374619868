import React from 'react'
import { classNames } from '../../utils/utils';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import AppRadioGroup from '../../widgets/AppRadioGroup';


const useStyles = makeStyles({
    container: {
        height: '100vh',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        minWidth: 275,
    },
    textField: {
        margin: "0 auto",
    },
    item: {
        marginBottom: "30px"
    }
});


export default function StepOneComponent({
    eventTypeOptions,
    onChange,
    name,
    type,
    isCreate,
    isTouched,
    onBlur,
    getError
}) {

    const classes = useStyles();


    return (
        <div>
            <TextField
                fullWidth
                className={classNames(classes.textField, classes.item)}
                id="filled-basic"
                label="Name"
                variant="outlined"
                value={name}
                onChange={(e) => onChange("name", e.target.value)}
                error={getError('name')}
                helperText={getError('name')}
                onBlur={onBlur.bind(this, "name")}
            />
            {isCreate ?
                <AppRadioGroup
                    title="Type"
                    options={eventTypeOptions}
                    className={classes.item}
                    value={type}
                    onChange={(e) => onChange("type", e.target.value)}
                />
                : null
            }

        </div>
    )
}

import React, { useCallback, useEffect, useState } from "react";
import { IEntriesFilter, IEntry } from "../models/interfaces/Entries";
import EntriesService from "../services/entries/entries.service";
import { arrayToEntity, entityToArray, sortEntries } from "../utils/utils";




export const EntriesContext = React.createContext<{
    actions: {
        addEntries: (entries: IEntry[]) => void
        setEntries: (entries: IEntry[]) => void
        addEntry: (entry: IEntry) => void
        removeEntry: (entry: IEntry) => void
        updateEntry: (entry: IEntry) => void,
        setDates: (entries: { from: Date, to: Date }[]) => void
    },
    selectors: {
        getEntry: (entryId: string) => IEntry,
        getEntries: () => IEntry[],
        getDates: () => { from: Date, to: Date }[]

    }
}>(null);


const EntriesContextProvider = ({
    children
}) => {

    const [entries, _setEntries] = useState<{ [id: string]: IEntry }>({});
    const [dates, _setDates] = useState<{ from: Date, to: Date }[]>([]);


    const setDates = useCallback((_dates: { from: Date, to: Date }[]) => {
        _setDates(_dates);
    }, [dates])

    const addEntries = useCallback((_entries: IEntry[]) => {
        _setEntries({ ...entries, ...arrayToEntity(_entries) });
    }, [entries])

    const setEntries = useCallback((_entries: IEntry[]) => {
        _setEntries(arrayToEntity(_entries));
    }, [entries])

    const addEntry = useCallback((entry: IEntry) => {
        _setEntries({ ...entries, [entry.id]: entry });
    }, [entries])

    const removeEntry = useCallback((entry: IEntry) => {
        const _entries = { ...entries };
        delete _entries[entry.id];
        _setEntries(_entries);
    }, [entries])

    const updateEntry = useCallback((entry: IEntry) => {
        _setEntries({ ...entries, [entry.id]: entry });
    }, [entries])

    const getEntries = useCallback(() => {
        return sortEntries(entityToArray(entries))
    }, [entries])

    const getDates = useCallback(() => {
        return dates
    }, [dates])



    const getEntry = useCallback((id: string) => entries[id], [entries])

    console.log("The dates in the context ", dates);

    return (
        <EntriesContext.Provider value={{
            actions: {
                addEntries,
                addEntry,
                setEntries,
                removeEntry,
                updateEntry,
                setDates
            },
            selectors: {
                getEntries,
                getEntry,
                getDates
            }
        }}>
            {children}
        </EntriesContext.Provider >
    )
}


export default EntriesContextProvider
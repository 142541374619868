import React, { useEffect, useMemo, useState } from "react";
import { noop } from "../utils/utils";
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

interface AlertProps {
    message: string;
    title?: string;
}

export const AlertContext = React.createContext<{
    info: (props: AlertProps) => void
    success: (props: AlertProps) => void,
    error: (props: AlertProps) => void
}>(null);

const AlertContextProvider = ({
    children
}) => {


    const [data, setOpen] = useState<{
        title: string,
        message: string,
        severity: string,
        timestamp: number
    }>(null);


    useEffect(() => {
        if (data) {
            const id = setTimeout(() => setOpen(null), 3000)
            return clearTimeout.bind(this, id);
        }

    }, [data?.timestamp])

    const info = ({ message, title = "Info" }: AlertProps) => {
        setOpen({ message, severity: 'info', title, timestamp: Date.now() })
    }

    const success = ({ message, title = "Success" }: AlertProps) => {
        setOpen({ message, severity: 'success', title, timestamp: Date.now() });
    }

    const error = ({ message, title = "Error" }: AlertProps) => {
        setOpen({ message, severity: 'error', title, timestamp: Date.now() });
    }


    return (
        <AlertContext.Provider value={{
            info,
            success,
            error
        }}>
            <Collapse in={!!data} style={{ position: 'absolute', width: "100%", zIndex: 50000000 }}>
                {!!data ? <Alert
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpen(null);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    severity={data.severity as any}
                >
                    <AlertTitle>{data.title}</AlertTitle>
                    {data.message}
                </Alert>
                    : null
                }
            </Collapse>
            {children}
        </AlertContext.Provider>
    )
}


export default AlertContextProvider
import React from 'react'
import firebase from 'firebase';
import { StyledFirebaseAuth } from 'react-firebaseui'
import Card from '@material-ui/core/Card';

const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // signInSuccessUrl: window.location.href,
    // We will display Google and Facebook as auth providers.
    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ],
};

export default function AuthComponent() {
    return (

        <Card style={{ marginTop: '-15em', textAlign: 'center',  minWidth: '300px', color: 'black', backgroundColor: 'white' }} >
            <h1>Please Login</h1>
            <StyledFirebaseAuth
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
            />
        </Card>
    )
}

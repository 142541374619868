import { IEntry } from "../models/interfaces/Entries";

export const classNames = (...args) => {
    return args.reduce((acc, cur) => `${acc} ${cur}`, '') as string
}


export const noop = () => null;

export const arrayToEntity = <T>(arr: any[]): { [id: string]: T } => arr.reduce((acc, data) => ({ ...acc, [data.id]: data }), {})
export const entityToArray = <T>(e: { [id: string]: T }): T[] => e?  Object.keys(e).map((key) => e[key]) : undefined

export const sortEntries = (data: IEntry[]) => {
    return data.sort((a, b) => {
        const x = timestampToDate(a.date.timestamp) as any;
        const y = timestampToDate(b.date.timestamp) as any;

        return x - y;
    });
}

export const mapKeysToDate = (data: any[], keys: string[]) => {
    return data.map((a) => {
        return keys.reduce((acc, k) => {
           
            const nested = k.split(".");
            let i = 0;
            let ref = acc;
            let key = nested[i]

            while(typeof ref[key] === 'object' && i < nested.length - 1) {
                ref = ref[key]
                key = nested[++i]
            }

            ref[key] = timestampToDate(ref[key]);
           
            return acc
        }, a)
    });
}

export const timestampToDate = (date): Date => {
    try {


        if (!date) return null;

        if (typeof date === "string") {
            return new Date(date);
        }

        if (date instanceof Date) {
            return date;
        }

        return date.toDate();


    } catch (e) {
        return null;
    }
}

export function titleCase(str) {
    return str.toLowerCase().split(' ').map(function(word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
  }
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { classNames } from '../utils/utils';

const useStyles = makeStyles((theme) => ({
    chip: {
        display: 'inline-block',
        padding: '5px 15px',
        height:"auto",
        fontSize: "16px",
        lineHeight: "50px",
        borderRadius: "25px",
        width: 'auto'
    },
    text: {
        margin: "0 !important",
        padding: 0
    },
    primary: {
        backgroundColor: theme.palette.primary.main
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main
    },
    danger: {
        backgroundColor: '#F40C1A'
    },
    success: {
        backgroundColor: '#28C94D'
    },
    dark: {
        backgroundColor: 'grey'
    }
}));


export default function AppChip({ children, color = "" }) {
    const classes = useStyles()
    return (
        <div className={classNames(classes.chip, classes[color])}>
            <p className={classes.text}>{children}</p>
        </div>
    )
}

import React from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

interface Props {
    title: string
    options: {label:string, value: string} [],
    className?: string,
    value,
    onChange
}

export default function AppRadioGroup({
    title,
    options,
    className,
    value,
    onChange
}: Props) {


    return (
        <FormControl component="fieldset" className={className}>
            <FormLabel component="legend">{title}</FormLabel>
            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={onChange}>
                {options.map((option, i) => (
                    <FormControlLabel key={i} value={option.value} control={<Radio />} label={option.label} />
                ))}
                
            </RadioGroup>
        </FormControl>
    );
}

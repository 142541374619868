import firebase from 'firebase';

type User = firebase.User

const auth = () => firebase.auth();

const authStatus = (cb: (user: User) => void) => auth().onAuthStateChanged((user = null) => cb(user));


const logout = async () => {
    await auth().signOut()
    window.location.replace("/")
}


const sendVerificationEmail = async () => {
    return await auth().currentUser.sendEmailVerification()
}


const getCurrentUser = () => auth().currentUser

const deleteUser = async () => {
    const user = await getCurrentUser();
    await user.delete()
}




const AuthService = {
    authStatus,
    sendVerificationEmail,
    getCurrentUser,
    deleteUser,
    logout
}

export default AuthService;
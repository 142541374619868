import { useFormik } from 'formik';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ManageTagComponent from '../../components/ManageTag/ManageTag.component';
import { ITag } from '../../models/interfaces/Tags';
import TagsPage from '../../pages/Tags/Tags.page';
import TagsService from '../../services/tags/tags.service';
import * as yup from 'yup';
import { AlertContext } from '../../contexts/alert.context';
import { ConfirmContext } from '../../contexts/confirm.context';
import { TagsContext } from '../../contexts/tags.context';



const initialValues = {
    name: ''
}

const validationSchema = yup.object().shape({
    name: yup.string().max(250).required()
})

export default function TagsContainer() {

    const [searchTxt, setSearchTxt] = useState<string>();
    const alert = useContext(AlertContext);

    const confirm = useContext(ConfirmContext);
    const { actions: {
        addTag,
        removeTag
    }, selectors: {
        getTags
    } } = useContext(TagsContext)


    const searchParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);
    const history = useHistory();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async ({ name }) => {

            const id = Date.now().toString();

            const tmpTag = { 
                name, 
                id: searchParams.get("tagId") || id, 
                createdAt: new Date(), 
                updatedAt: new Date() 
            };

            history.replace(history.location.pathname);
            try {
                addTag(tmpTag);
                if (searchParams.get('action') === 'edit') {
                    
                    await TagsService.updateTag(searchParams.get("tagId"), { name });

                } else {

                    await TagsService.createTag(name);
                }
                alert.success({ message: `${name} created sucessfully` });

            } catch (e) {
                alert.error({ message: e.message });
                removeTag({ name, id, createdAt: new Date(), updatedAt: new Date() });
            }
        }
    })

    const getError = useCallback((field) => !!formik.touched[field] ? formik.errors[field] || "" : "", [formik.errors, formik.touched]);
    const onHandleChange = (key: string, value) => {
        formik.setFieldTouched(key);
        formik.setFieldValue(key, value);
    }

    useEffect(() => {
        // Set the form data based on if the mode is edit
        if (searchParams.get('action') === 'edit') {

            const tagId = searchParams.get("tagId");
            TagsService.getTag(tagId).then((tag) => {
                if (tag) {

                    formik.setValues({
                        name: tag.name
                    });

                }
            })


        }
    }, [searchParams]);


    const onAddPress = () => {
        history.push(history.location.pathname + "?action=create");
    }

    const onEditPress = (tag) => {
        history.push(history.location.pathname + `?action=edit&tagId=${tag.id}`);
    }

    const onDeletePress = (tag) => {
        confirm.show({
            title: "Are you sure",
            message: `You are about to delete ${tag.name}`,
            actions: [
                {
                    title: "Yes",
                    role: 'destructive',
                    handler: async () => {
                        try {

                            await TagsService.deleteTag(tag.id);
                            alert.success({ message: `Sucessfully deleted ${tag.name}` });
                            removeTag(tag)

                        } catch (e) {
                            alert.error({ message: e.message })
                        }

                    }
                }
            ]
        })
    }


    const handleModalClose = () => {
        history.replace(history.location.pathname);
        formik.resetForm();
    }


    const tags = useMemo(() => getTags()?.filter(({name}) => searchTxt ? name.toLowerCase().includes(searchTxt.toLowerCase()) : true), [getTags, searchTxt]);

    return (
        <>
            <TagsPage
                onAddPress={onAddPress}
                onDeletePress={onDeletePress}
                onEditPress={onEditPress}
                tags={tags}
                searchTxt={searchTxt}
                setSearchTxt={setSearchTxt}
            />
            <ManageTagComponent
                name={formik.values.name}
                isCreate={searchParams.get("action") === 'create'}
                open={!!searchParams.get("action")}
                handleClose={handleModalClose}
                onChange={onHandleChange}
                onBlur={formik.setFieldTouched}
                getError={getError}
                isValid={formik.isValid && formik.dirty}
                onSavePress={formik.handleSubmit}
            />
        </>
    )
}
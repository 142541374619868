import React from 'react'
import { classNames } from '../../utils/utils';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from "@material-ui/pickers";
import Typography from '@material-ui/core/Typography';
import { EntryType } from '../../models/interfaces/Entries';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';



const useStyles = makeStyles((theme) => ({
    container: {
        height: '100vh',
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        minWidth: 275,
    },
    textField: {
        margin: "0 auto",
    },
    item: {
        marginBottom: "30px"
    },
    dateItem: {
        width: '100%',
        minWidth: "180px",
        marginBottom: "10px",
        [theme.breakpoints.up("md")]: {
            marginRight: '2em',
        }
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up("md")]: {
            justifyContent: 'start',
            flexDirection: 'row',
        }
    },
    dateTitle: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    tags: {
        width: '100%',
        marginBottom: "2em"
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


export default function StepTwoComponent({
    onChange,
    description,
    location,
    date,
    tagList,
    tags,
    type,
    artist,
    album,
    isAdmin,
    newsSource,
    onBlur,
    getError
}) {


    const classes = useStyles();
    return (
        <div>

            {type === EntryType.EVENT ?
                <>
                    <TextField
                        className={classNames(classes.textField, classes.item)}
                        fullWidth
                        multiline
                        id="filled-basic"
                        label="Description"
                        variant="outlined"
                        rows={5}
                        value={description}
                        onChange={(e) => onChange("description", e.target.value)}
                        error={getError('description')}
                        helperText={getError('description')}
                        onBlur={onBlur.bind(this, "description")}
                    />
                    <TextField
                        fullWidth
                        className={classNames(classes.textField, classes.item)}
                        id="filled-basic"
                        label="Location"
                        variant="outlined"
                        value={location}
                        onChange={(e) => onChange("location", e.target.value)}
                        error={getError('location')}
                        helperText={getError('location')}
                        onBlur={onBlur.bind(this, "location")}
                    />
                    <TextField
                        fullWidth
                        className={classNames(classes.textField, classes.item)}
                        id="filled-basic"
                        label="News Source (optional)"
                        variant="outlined"
                        value={newsSource}
                        onChange={(e) => onChange("newsSource", e.target.value)}
                        error={getError('newsSource')}
                        helperText={getError('newsSource')}
                        onBlur={onBlur.bind(this, "newsSource")}
                    />
                </>
                :
                <>
                    <TextField
                        fullWidth
                        className={classNames(classes.textField, classes.item)}
                        id="filled-basic"
                        label="Artist"
                        variant="outlined"
                        value={artist}
                        onChange={(e) => onChange("artist", e.target.value)}
                        error={getError('artist')}
                        helperText={getError('artist')}
                        onBlur={onBlur.bind(this, "artist")}
                    />
                    <TextField
                        fullWidth
                        className={classNames(classes.textField, classes.item)}
                        id="filled-basic"
                        label="Album"
                        variant="outlined"
                        value={album}
                        onChange={(e) => onChange("album", e.target.value)}
                        error={getError('album')}
                        helperText={getError('album')}
                        onBlur={onBlur.bind(this, "album")}
                    />
                </>
            }
            {isAdmin ? (
                <FormControl className={classes.tags}>
                    <InputLabel>Tags (optional)</InputLabel>
                    <Select
                        labelId="demo-mutiple-checkbox-label"
                        multiple
                        value={tags || []}
                        onChange={(e) => {
                            e.stopPropagation();
                            console.log("The value ", e.target.value)
                            onChange("tags", e.target.value);
                        }}
                        input={<Input />}
                        renderValue={(selected: any[]) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        {tagList.map(({ name }) => (
                            <MenuItem key={name} value={name}>
                                <Checkbox checked={tags && tags.includes(name)} />
                                <ListItemText primary={name} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                )
                : null}

            <Typography component="h2"
                className={classes.dateTitle}
            >
                Date
            </Typography>

            <div className={classNames(classes.dateContainer)}>
                <TextField
                    fullWidth
                    className={classNames(classes.dateItem, classes.item)}
                    id="filled-basic"
                    label="Day (optional)"
                    variant="outlined"
                    value={date.day}
                    type='number'
                    onChange={(e) => onChange("day", e.target.value)}
                    error={getError('day')}
                    helperText={getError('day')}
                    onBlur={onBlur.bind(this, "day")}
                />
                <TextField
                    fullWidth
                    className={classNames(classes.dateItem, classes.item)}
                    id="filled-basic"
                    label="Month (optional)"
                    variant="outlined"
                    value={date.month}
                    type='number'
                    onChange={(e) => onChange("month", e.target.value)}
                    error={getError('month')}
                    helperText={getError('month')}
                    onBlur={onBlur.bind(this, "month")}
                />
                <DatePicker
                    className={classNames(classes.dateItem)}
                    views={["year"]}
                    label="Year"
                    value={date.year}
                    onChange={(e) => onChange("year", e)}
                    maxDate={new Date()}
                    minDate={new Date('1800')}
                />
            </div>
        </div >
    )
}

import { DatabaseService } from "../database/database.service"


const getAggrigates = async () => {
    const artists = await DatabaseService.getDoc<{
        [id: string]: boolean
    }>("aggrigates/artists");

    const titles = await DatabaseService.getDoc<{
        [id: string]: boolean
    }>("aggrigates/titles");

    if(artists) {
        delete artists.id;
    }
   
    if(titles) {
        delete titles.id;
    }
  

    return { artists: artists || {}, titles: titles || {} };
}


const AggrigatesService = {
    getAggrigates
}


export default AggrigatesService
import firebase from 'firebase';
import { ENVIRONMENT } from '.';



const production = {
    apiKey: "AIzaSyDOpOIbAKaGRPzOW2zxD1D79hJMK0xdId0",
    authDomain: "weve-been-protesting.firebaseapp.com",
    databaseURL: "https://weve-been-protesting.firebaseio.com",
    projectId: "weve-been-protesting",
    storageBucket: "weve-been-protesting.appspot.com",
    messagingSenderId: "746333576095",
    appId: "1:746333576095:web:e81589d833c53cea627baa",
    measurementId: "G-KNBJ253CJG"
};


const development = {
    apiKey: "AIzaSyD7sMvEF85VpIUDumYT8vQ84eFBw0SdsKA",
    authDomain: "dev-wevebeenprotesting.firebaseapp.com",
    projectId: "dev-wevebeenprotesting",
    storageBucket: "dev-wevebeenprotesting.appspot.com",
    messagingSenderId: "144373434883",
    appId: "1:144373434883:web:b4d90d1143518704559c36"
}

const initializeFirebaseApp = () => {
    console.log("initializing app...", ENVIRONMENT)

    let config = development;

    if (ENVIRONMENT === 'production') {
        config = production;
    }

    firebase.initializeApp(config);
}


export const dbRef = firebase.firestore


export default initializeFirebaseApp;



import { ITag } from "./Tags";

export enum EntryType {
    NONE = "",
    EVENT = "event",
    SONG = "song"
}

export enum EntryStatus {
    ACTIVE = "active",
    NEW = "new",
    UPDATED = "updated",
    DELETED = "deleted"
}


export interface IEntriesFilter {
    from: Date;
    to: Date;
    artist: string;
    songName: string;
    createdBy: string;
    pendingApproval: boolean;
    tags: string[];
    decade: number
}

export interface IEntry {
    id?: string;
    notes?: string;
    type: EntryType
    title: string;
    creatorId: string;
    tags?: string[];
    createdAt?: Date;
    upatedAt?: Date; 
    status: EntryStatus
    date: {
        day: string,
        month: string,
        year: string,
        timestamp: Date
    }
}


export interface IEvent extends IEntry {
    location?: string;
    description: string;
    newsSource?: string;
}

export interface ISong extends IEntry {
    artist: string;
    album: string;
    geniusSongAPIPath?: string;
    genre?: string;
    publishingCompany?: string;
    songWriter?: string;
    yearPublished?: string;
}

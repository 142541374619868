import { DB_COLLECTIONS } from "../../constants/database";
import { ITag } from "../../models/interfaces/Tags";
import { DatabaseService } from "../database/database.service";


const getTags = () => {
    return DatabaseService.getCol<ITag>(DB_COLLECTIONS.getTags())
}

const getTag = (id: string) => {
    return DatabaseService.getDoc<ITag>(DB_COLLECTIONS.getTag(id))
}

const createTag = (name: string) => {
    return DatabaseService.add<ITag>(DB_COLLECTIONS.getTags(), { name } as ITag);

}

const updateTag = (id: string, tag: Partial<ITag>) => {
    return DatabaseService.update<ITag>(DB_COLLECTIONS.getTag(id), tag as ITag);
}

const deleteTag = (id: string) => {
    return DatabaseService.remove(DB_COLLECTIONS.getTag(id));
}



const TagsService = {
    getTags,
    createTag,
    updateTag,
    deleteTag,
    getTag
}

export default TagsService;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import initializeFirebaseApp from './app/environment/firebase'
import { ENVIRONMENT } from './app/environment';

initializeFirebaseApp();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log = ENVIRONMENT !== 'production' ? console.log : () => null;
console.error = ENVIRONMENT !== 'production' ? console.error : () => null;

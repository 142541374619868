import { DB_COLLECTIONS } from "../../constants/database";
import { EntryStatus, IEntriesFilter, IEntry, ISong } from "../../models/interfaces/Entries";
import { mapKeysToDate, timestampToDate } from "../../utils/utils";
import { DatabaseService } from "../database/database.service";


const getEntries = async (
    from?: Date,
    to?: Date) => {
    const timeline = await DatabaseService.getCol<IEntry>(
        DB_COLLECTIONS.getEntries(),
        (_ref) => {

            let ref = _ref
                .orderBy
                ("date.timestamp", 'desc')
                .where('status', '==', EntryStatus.ACTIVE)


            if (from) {
                ref = ref.where('date.timestamp', '>=', from)
            }

            if (to) {
                ref = ref.where('date.timestamp', '<=', to)
            }

            return ref
        }
    )


    return mapKeysToDate(timeline, ['date.timestamp', 'createdAt', 'updatedAt']);
}

const getEntry = async (id: string) => {
    return await DatabaseService.getDoc<IEntry>(DB_COLLECTIONS.getEntry(id))
}

const getInactiveEntries = async (
    from?: Date,
    to?: Date) => {
    return await DatabaseService.getCol<IEntry>(
        DB_COLLECTIONS.getEntries(),
        (_ref) => {
            let ref = _ref
                .where('status', '!=', EntryStatus.ACTIVE)


            return ref
        }
    ).then((e) => mapKeysToDate(e, ['date.timestamp', 'createdAt', 'updatedAt']))
        .then((entries) => entries.filter((entry) => {
            const date = timestampToDate(entry.date.timestamp)
            console.log("the entry date", { date, from, to })
            if (from && date <= from) return false;
            if (to && date >= to) return false;

            return true
        }))
}

const getEntriesByArtist = async (
    artist: string,
    from?: Date,
    to?: Date
) => {
    return await DatabaseService.getCol<IEntry>(
        DB_COLLECTIONS.getEntries(),
        (_ref) => {
            let ref = _ref.orderBy("date.timestamp", 'desc')
                .where('artist', '==', artist)

            if (from) {
                ref = ref.where("date.timestamp", ">=", from)
            }

            if (to) {
                ref = ref.where("date.timestamp", "<=", to)
            }

            return ref
        }
    ).then((e) => mapKeysToDate(e, ['date.timestamp', 'createdAt', 'updatedAt']))
}

const getEntriesByTitle = async (
    title: string,
    from?: Date,
    to?: Date
) => {
    return await DatabaseService.getCol<IEntry>(
        DB_COLLECTIONS.getEntries(),
        (_ref) => {

            let ref = _ref.orderBy("date.timestamp", 'desc')
                .where('title', '==', title)

            if (from) {
                ref = ref.where("date.timestamp", ">=", from)
            }

            if (to) {
                ref = ref.where("date.timestamp", "<=", to)
            }

            return ref
        }
    ).then((e) => mapKeysToDate(e, ['date.timestamp', 'createdAt', 'updatedAt']))
}

const getEntriesByTags = async (tags: string[]) => {
    return await DatabaseService.getCol<IEntry>(
        DB_COLLECTIONS.getEntries(),
        (ref) => ref.where('tags', 'array-contains-any', tags)
    )
}

const getEntriesByUserId = async (
    userId: string,
    from?: Date,
    to?: Date
) => {
    return await DatabaseService.getCol<IEntry>(
        DB_COLLECTIONS.getEntries(),
        (_ref) => {
            let ref = _ref
                .orderBy("date.timestamp")
                .where('creatorId', '==', userId)

            if (from) {
                ref = ref.where("date.timestamp", ">=", from)
            }

            if (to) {
                ref = ref.where("date.timestamp", "<=", to)
            }



            return ref;
        }
    ).then((e) => mapKeysToDate(e, ['date.timestamp', 'createdAt', 'updatedAt']))
}

const createEntry = async (entry: IEntry) => {

    const { id } = await DatabaseService.add(DB_COLLECTIONS.getEntries(), entry);

    return { id, ...entry }
}


const updateEntry = async (id: string, entry: IEntry) => {
    await DatabaseService.update(DB_COLLECTIONS.getEntry(id), entry);
    return { id, ...entry }
}

const deleteEntry = async (id: string) => {
    return await DatabaseService.remove(DB_COLLECTIONS.getEntry(id));
}


const getEntriesByFilter = async (filters: IEntriesFilter) => {
    try {

        console.log("the filters ", filters);
        if (filters?.createdBy) {
            return await getEntriesByUserId(filters.createdBy, filters.from, filters.to)
        }

        if (filters?.pendingApproval) {
            return await getInactiveEntries(filters.from, filters.to)
        }

        let entries: IEntry[] = [];


        if (filters?.from && filters.to) {
            entries = await getEntries(filters.from, filters.to);
        }


        if (filters?.songName && filters.from && filters.to) {
            entries = await getEntriesByTitle(filters.songName, filters.from, filters.to)
        }

        if (filters?.artist && filters.from && filters.to) {
            const eartist = await getEntriesByArtist(filters.artist, filters.from, filters.to)
            entries = [...entries.filter((e: ISong) => e.artist === filters.artist), ...eartist]
        }

        if (filters.decade) {

            const start = new Date(`${1}/${1}/${filters.decade}`);
            const end = new Date(`${1}/${1}/${filters.decade + 10}`);

            if (entries.length === 0) {
                entries = await getEntries(start, end);
            } else {
                entries = [...entries.filter(e => timestampToDate(e.date.timestamp) >= start && timestampToDate(e.date.timestamp) <= end)]
            }

        }

        if (filters?.tags?.length) {
            const etags = await getEntriesByTags(filters.tags);
            const tagsSet = filters.tags.reduce((a, c) => ({ ...a, [c]: true }), {});
            entries = [
                ...entries.filter((e) => e.tags && e.tags.find((t) => tagsSet[t])),
                ...etags
            ];
        }



        const set = {}

        return entries.filter((e) => {

            if (set[e.id] === undefined) {
                set[e.id] = true
                return set[e.id] = true
            }

            return false
        })

    } catch (e) {
        throw e;
    }

}

const getTimelineDecades = async () => {
    try {

        const [oldest] = await DatabaseService.getCol<IEntry>(DB_COLLECTIONS.getEntries(), ref => ref.orderBy('date.timestamp', 'asc').limit(1));
        const [latest] = await DatabaseService.getCol<IEntry>(DB_COLLECTIONS.getEntries(), ref => ref.orderBy('date.timestamp', 'asc').limitToLast(1));

        const oldest_time = timestampToDate(oldest.date.timestamp);
        const latest_time = timestampToDate(latest.date.timestamp);

        const buckets = [];

        const q = [new Date('1/1/' + oldest_time.getFullYear())];

        while (q.length > 0) {
            const curr = q.shift();

            const next = new Date(curr);
            next.setFullYear(curr.getFullYear() + 10);

            if (next < latest_time) {
                buckets.push({ from: curr, to: next, id: curr.toDateString() });
                q.push(next);
            }
        }

        console.log("The buckets bf last ", buckets);

        if (buckets.length)
            buckets[buckets.length - 1] = { ...buckets[buckets.length - 1], to: new Date() };

        return buckets;
    } catch (e) {

        return [];
    }

}




const EntriesService = {
    getEntries,
    createEntry,
    updateEntry,
    deleteEntry,
    getEntry,
    getInactiveEntries,
    getEntriesByArtist,
    getEntriesByTitle,
    getEntriesByUserId,
    getEntriesByFilter,
    getEntriesByTags,
    getTimelineDecades
}

export default EntriesService
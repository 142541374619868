import Button from '@material-ui/core/Button'
import React, { useMemo } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            textAlign: 'center',
            marginTop: "5em"
        }
    }),
);

export default function EmailVerificationPage({
    loading,
    emailSent,
    email,
    onSendVerificationPress,
    onRefreshPress
}) {

    const classes = useStyles();

    console.log("the loaidng ", loading)

    const renderView = useMemo(() => {
        let view = (
            <Button
                variant="contained"
                color="secondary"
                onClick={onSendVerificationPress}
            >
                Send Verification Email
            </Button>
        )

        if (emailSent) {
            view = (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={onRefreshPress}
                >Next
                </Button>
            )
        }
        if (loading) {
            view = (
                <CircularProgress color="secondary" />
            )
        }


        return view
    }, [loading, emailSent])

    return (
        <div className={classes.container}>

            <h1>Please verify your email</h1>
            <h3>{email}</h3>

            {emailSent ? <h4>A verification email has been sent, complete it and click next!</h4> : null}

            <div className="actions">
                {/* {!emailSent ?
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onSendVerificationPress}
                    >
                        Send Verification Email
                </Button>
                    :
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={onRefreshPress}
                    >Next
                </Button>
                } */}

                {renderView}
            </div>


        </div>
    )
}
function usememo(arg0: () => void, arg1: any[]) {
    throw new Error('Function not implemented.');
}


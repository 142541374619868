import React from 'react'
import { VerticalTimeline } from 'react-vertical-timeline-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import TimelineElement from '../../components/timeline/TimelineElement';
import FilterListIcon from '@material-ui/icons/FilterList';
import Fab from '@material-ui/core/Fab';
import { makeStyles } from '@material-ui/core/styles';
import { IUser } from '../../models/interfaces/User';
import { IEntry } from '../../models/interfaces/Entries';
import InfiniteScroll from "react-infinite-scroll-component";



const useStyles = makeStyles((theme) => ({
    progress: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        marginLeft: '-10px'
    },
    filterIcon: {
        position: 'fixed',
        top: '8%',
        right: "1%",
        zIndex: 5,
        [theme.breakpoints.up('md')]: {
            top: '9%',
            left: "2%",
        }
    },
    fab: {
        position: 'fixed',
        right: "5%",
        bottom: "5%",
        [theme.breakpoints.up('md')]: {
            right: "5%",
            bottom: "10%",
        }
    }
}));




interface Props {
    entries: IEntry[],
    loading: boolean,
    drawerOpen: boolean,
    user: IUser,
    onEditPress: (entry: IEntry) => void,
    onDeletePress: (entry: IEntry) => void,
    onFilterPress: () => void,
    determinRole: (entry: IEntry) => string,
    onUndoDeletePress: (entry: IEntry) => void,
    onApprovePress: (entry: IEntry) => void
}

export default React.memo<Props>(function TimelinePage({
    entries,
    loading,
    drawerOpen,
    determinRole,
    onDeletePress,
    onEditPress,
    onFilterPress,
    onUndoDeletePress,
    onApprovePress
}: Props) {

    const classes = useStyles();

    if (!loading) {

        return (
            <>
                <div className={classes.filterIcon}>
                    {!drawerOpen &&
                        <Fab onClick={onFilterPress} color="primary" size="small" aria-label="add">
                            <FilterListIcon />
                        </Fab>

                    }

                </div>

                <VerticalTimeline

                >

                    {
                        entries.map((item) => (
                            <TimelineElement
                                key={item.id}
                                entry={item}
                                role={determinRole(item)}
                                onDeletePress={onDeletePress}
                                onEditPress={onEditPress}
                                onUndoDeletePress={onUndoDeletePress}
                                onApprovePress={onApprovePress}
                            />
                        ))
                    }



                </VerticalTimeline>
            </>
        );
    } else {

        return (

            <CircularProgress className={classes.progress} />
        );

    }
})

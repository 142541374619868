import React, { useContext } from 'react';
import clsx from 'clsx';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import NavbarComponent from '../Navigation/Navbar/Navbar.component';
import Sidemenu from '../Navigation/Sidemenu/Sidemenu.component';
import DrawerContext from '../../contexts/drawer.context';
import FiltersComponent from '../Filters/Filters.component';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { classNames } from '../../utils/utils';
import { AuthContext } from '../../contexts/auth.context';
import Typography from '@material-ui/core/Typography';
import AlertContextProvider from '../../contexts/alert.context';
import { ConfirmContext } from '../../contexts/confirm.context';
import UserService from '../../services/user/user.service';
import { UserContext } from '../../contexts/user.context';

const drawerWidth = 270;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        height: '100%'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        textAlign: 'center'
    },
    drawerChevron: {
        // marginTop: '65px',
    },
    content: {
        flexGrow: 1,

        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        height: "100%"
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    scroll: {
        overflowX: 'scroll',
        height: "100%",
        padding: theme.spacing(3),
    },
    filtersTitle: {
        fontSize: "30px",
        fontWeight: 'bold',
        textAlign: 'center',
        marginRight: "2em"
    }
}));

export default function LayoutComponent({
    children
}) {
    const classes = useStyles();
    const theme = useTheme();
    const [
        {
            content,
            open
        },
        setOpen
    ] = React.useState({
        content: "filters",
        open: false
    });

    const { user: authUser, logout } = useContext(AuthContext);
    const user = useContext(UserContext)

    const confirm = useContext(ConfirmContext)


    const setFilters = (_open) => {
        setOpen({
            content: 'filters',
            open: _open
        });
    }

    const setMenu = (_open) => {
        setOpen({
            content: 'menu',
            open: _open
        });
    }

    const onLogoutPress = () => {
        logout();
    }

    const onDeleteAccountPress = () => {
        confirm.show({
            title: "Are you Sure",
            message: "You are about to delete your account",
            actions: [
                {
                    title: "Yes",
                    role: 'destructive',
                    handler: async () => {
                        await UserService.deleteUser(authUser.uid);
                        window.location.reload();
                    }
                }
            ]
        })
    }

    return (
            <AlertContextProvider>
                <DrawerContext.Provider value={{ setOpen, open }}>
                    <div className={classes.root}>

                        <CssBaseline />

                        <NavbarComponent
                            open={open}
                            setMenuOpen={setMenu}
                            isAuth={!!authUser}
                            isAdmin={user?.isAdmin}
                            onLogoutPress={onLogoutPress}
                            onDeleteAccountPress={onDeleteAccountPress}
                        />

                        <Drawer
                            className={classes.drawer}
                            variant="persistent"
                            anchor="left"
                            open={open}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <div className={classNames(classes.drawerHeader, classes.drawerChevron)}>
                                <Typography className={classes.filtersTitle} component="h2">
                                    {content === 'filters' ?
                                        "Filters"
                                        :
                                        "Menu"
                                    }
                                </Typography>
                                <IconButton onClick={() => setFilters(false)}>
                                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                                </IconButton>

                            </div>
                            <Divider />
                            {content === 'filters' ?
                                <FiltersComponent />
                                :
                                <Sidemenu
                                    onCloseClicked={() => setMenu(false)}
                                    onLogoutPress={onLogoutPress}
                                    onDeleteAccountPress={onDeleteAccountPress}
                                    isAuth={!!authUser}
                                    isAdmin={!!user && user.isAdmin}
                                />
                            }

                        </Drawer>

                        <main className={clsx(classes.content, { [classes.contentShift]: open, })} >
                            <div className={classes.drawerHeader} />
                            <div className={classes.scroll}>
                                {children}
                                <div  style={{height: '40px'}}/>
                            </div>
                        </main>


                    </div>
                </DrawerContext.Provider>
            </AlertContextProvider>
    )
}

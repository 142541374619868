import React, { useCallback, useContext, useState } from "react";
import { useEffect } from "react";
import { ITag } from "../models/interfaces/Tags";
import TagsService from "../services/tags/tags.service";
import { arrayToEntity, entityToArray } from "../utils/utils";
import { AuthContext } from "./auth.context";



export const TagsContext = React.createContext<{
    actions: {
        addTags: (tags: ITag[]) => void
        setTags: (tags: ITag[]) => void
        addTag: (tag: ITag) => void
        removeTag: (tag: ITag) => void
        updateTag: (tag: ITag) => void,
    },
    selectors: {
        getTag: (TagId: string) => ITag,
        getTags: () => ITag[]
    }
}>(null);


const TagsContextProvider = ({
    children
}) => {

    const [tags, _setTags] = useState<{ [id: string]: ITag }>();
    const {user} = useContext(AuthContext)

    // console.log("The tags entity ", tags);
    useEffect(() => {
        if(user) {
            TagsService.getTags().then((t) => _setTags(arrayToEntity(t)));
        } 
    }, [user])


    const addTags = useCallback((_Tags: ITag[]) => {
        _setTags({ ...tags, ...arrayToEntity(_Tags) });
    }, [tags])

    const setTags = useCallback((_tags: ITag[]) => {
        _setTags(arrayToEntity(_tags));
    }, [tags])

    const addTag = useCallback((tag: ITag) => {
        _setTags({ ...tags, [tag.id]: tag });
    }, [tags])

    const removeTag = useCallback((entry: ITag) => {
        const _tags = { ...tags };
        delete _tags[entry.id];
        _setTags(_tags);
    }, [tags])

    const updateTag = useCallback((tag: ITag) => {
        _setTags({ ...tags, [tag.id]: tag });
    }, [tags])


    const getTags = useCallback(() => entityToArray(tags) || undefined, [tags]);
    const getTag = useCallback((id: string) => tags[id], [tags]);


    return (
        <TagsContext.Provider value={{
            actions: {
                addTags,
                addTag,
                setTags,
                removeTag,
                updateTag,
            },
            selectors: {
                getTags,
                getTag
            }
        }}>
            {children}
        </TagsContext.Provider >
    )
}


export default TagsContextProvider
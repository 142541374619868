import React, { useContext, useMemo } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { classNames, noop, titleCase } from '../../utils/utils';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { UserContext } from '../../contexts/user.context';
import { FiltersContext } from '../../contexts/filters.context';
import { IEntriesFilter } from '../../models/interfaces/Entries';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { AggrigatesContext } from '../../contexts/aggrigates';
import { TagsContext } from '../../contexts/tags.context';
import { EntriesContext } from '../../contexts/entries.context';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';




const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(1),
            flexDirection: 'column'
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: "100%",
        },
        formControl: {
            margin: theme.spacing(1),
            width: "100%",
        },
        filterItem: {
            margin: "1em 0",
            width: "100%"
        },
        list: {
            margin: 0,
            width: "100%",
            padding: 0
        },
        listItem: {
            margin: '7px 0'
        },
        space: {
            margin: "35px 0",
            width: "100%",
        },
        decadesContainer: {
            padding: '5px',
            width: '100%'
        },
        decadesLabel: {
            fontSize: '13px'
        },
        decadesInfo: {
            padding: '15px',
            maxWidth: '250px',
            textOverflow: 'wrap'
        }
    }),
);

const defaultFilters: IEntriesFilter = {
    from: null,
    to: new Date(),
    artist: "",
    songName: "",
    createdBy: "",
    pendingApproval: false,
    tags: [],
    decade: (new Date()).getFullYear()

}


const validationSchema = yup.object().shape({
    from: yup.date(),
    to: yup.date(),
    artist: yup.string(),
    songName: yup.string(),
    createdBy: yup.string(),
    pendingApproval: yup.boolean()
})

export default function FiltersComponent() {
    const classes = useStyles();

    const {
        filters: appliedFilters,
        applyFilters
    } = useContext(FiltersContext);

    const { artists, titles } = useContext(AggrigatesContext);
    const {
        selectors: {
            getDates
        }
    } = useContext(EntriesContext)


    const decades = useMemo(() => getDates(), [getDates]);

    const {
        selectors: {
            getTags
        }
    } = useContext(TagsContext)

    const formik = useFormik({
        initialValues: defaultFilters,
        validationSchema,
        onSubmit: noop
    })

    const touched = useMemo(() => !!Object.keys(formik.touched).length, [formik.touched]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const {
        from,
        to,
        createdBy,
        pendingApproval,
        artist,
        songName,
        tags,
        decade
    } = formik.values;

    const user = useContext(UserContext);
    const tagList = useMemo(() => getTags(), [getTags]);

    const onHandleChange = (key: string, value) => {
        formik.setFieldTouched(key);
        formik.setFieldValue(key, value);
    }

    const onApplyFiltersPress = () => {
        const filters = { ...formik.values };
        filters.tags = tagList?.filter(({ name }) => tags.includes(name)).map(t => t.id) || [];
        applyFilters(filters);
    }

    const getDecadeText = (value) => {
        return value.toString();
    }


    return (
        <form className={classes.container} noValidate>

            {!!decades?.length ?
                <div className={classes.decadesContainer}>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <p className={classes.decadesInfo}>
                            Used to select a particular decade to view, starting at the selected year and ending 10 years after.
                        </p>
                    </Popover>

                    <Typography
                        id="discrete-slider"
                        gutterBottom
                        className={classes.decadesLabel}
                    >
                        Decades
                        <IconButton onClick={handleClick}>
                            <InfoIcon style={{ fontSize: 14 }} />
                        </IconButton>

                    </Typography>

                    <Slider
                        defaultValue={30}
                        getAriaValueText={getDecadeText}
                        aria-labelledby="discrete-slider"
                        valueLabelDisplay="auto"
                        step={10}
                        marks={true}
                        min={decades[0].from.getFullYear()}
                        max={decades[decades.length - 1].to.getFullYear()}
                        onChange={(_, v) => onHandleChange("decade", v)}
                        value={decade}
                    />
                </div>
                : null

            }


            <KeyboardDatePicker
                disableToolbar
                margin="normal"
                id="from"
                label="From"
                variant="inline"
                className={classNames(classes.textField, classes.filterItem)}
                format="MM/dd/yyyy"
                value={from || new Date()}
                minDate={new Date("1800")}
                onChange={(val) => onHandleChange("from", val)}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />

            <KeyboardDatePicker
                disableToolbar
                margin="normal"
                label="To"
                variant="inline"
                className={classNames(classes.textField, classes.filterItem)}
                format="MM/dd/yyyy"
                value={to || new Date()}
                onChange={(val) => onHandleChange("to", val)}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />

            <div className={classes.space}>

                <FormControl className={classNames(classes.formControl, classes.filterItem)}>
                    <InputLabel id="artist-name-select-label">Artist Name</InputLabel>
                    <Select
                        labelId="artist-name-select-label"
                        id="artist-name"
                        value={artist}
                        onChange={({ target }) => onHandleChange("artist", target.value)}
                    >
                        {artists.map((v, i) => (
                            <MenuItem key={i} value={v}>{titleCase(v)}</MenuItem>
                        )
                        )}
                    </Select>
                </FormControl>

                <FormControl className={classNames(classes.formControl, classes.filterItem)}>
                    <InputLabel id="song-name-select-label">Song Name</InputLabel>
                    <Select
                        labelId="song-name-select-label"
                        id="song-name"
                        value={songName}
                        onChange={({ target }) => onHandleChange("songName", target.value)}
                    >
                        {titles.map((v, i) => (
                            <MenuItem key={i} value={v}>{titleCase(v)}</MenuItem>
                        )
                        )}
                    </Select>
                </FormControl>

                <FormControl className={classNames(classes.formControl, classes.filterItem)}>
                    <InputLabel id="song-name-select-label">Song Name</InputLabel>
                    <Select
                        labelId="song-name-select-label"
                        id="song-name"
                        value={songName}
                        onChange={({ target }) => onHandleChange("songName", target.value)}
                    >
                        {titles.map((v, i) => (
                            <MenuItem key={i} value={v}>{titleCase(v)}</MenuItem>
                        )
                        )}
                    </Select>
                </FormControl>

                {user?.isAdmin ? (
                    <FormControl className={classNames(classes.formControl, classes.filterItem)}>
                        <InputLabel>Tags</InputLabel>
                        <Select
                            labelId="demo-mutiple-checkbox-label"
                            multiple
                            value={tags || []}
                            onChange={(e) => {
                                e.stopPropagation();
                                onHandleChange("tags", e.target.value);
                            }}
                            renderValue={(selected: any[]) => selected.join(', ')}
                        >
                            {tagList.map(({ name }) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox checked={tags && tags.includes(name)} />
                                    <ListItemText primary={name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
                    : null}


                <FormControl className={classes.formControl}>
                    <List className={classes.list} dense>
                        {user ?
                            <ListItem button className={classes.listItem}>
                                <ListItemText id="foo" primary="Created By Me" />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={({ target }) => onHandleChange("createdBy", target.checked ? user.id : "")}
                                        checked={!!createdBy}
                                        inputProps={{ 'aria-labelledby': 'foo' }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            :
                            null
                        }
                        {user?.isAdmin ?
                            <ListItem button className={classes.listItem}>
                                <ListItemText id="foo" primary="Pending Approval" />
                                <ListItemSecondaryAction>
                                    <Checkbox
                                        edge="end"
                                        onChange={({ target }) => onHandleChange("pendingApproval", target.checked)}
                                        checked={pendingApproval}
                                        inputProps={{ 'aria-labelledby': 'foo' }}
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                            :
                            null
                        }

                    </List>
                </FormControl>

            </div>
            <Button
                variant="contained"
                color="primary"
                onClick={onApplyFiltersPress}
                disabled={!touched}
            >
                Apply
            </Button>
            <div style={{ height: "1em" }} />
            <Button
                variant="contained"
                color="default"
                onClick={() => {
                    applyFilters(null);
                    formik.resetForm();
                }}
                disabled={!!appliedFilters === false}
            >
                Reset
            </Button>

        </form>


    )
}

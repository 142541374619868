import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  delete_sm: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },

  delete_md: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },


}));




function About() {

  const styles = useStyles()

  return (
    <Container>
      <Typography variant='h2' paragraph='true'>About This Project</Typography>
      <Typography variant='h6' paragraph='true' style={{ fontStyle: 'italic' }}>"The story of black people in America, is the story of America." - James Baldwin</Typography>
      <Typography paragraph='true'>
        A Protest is, "an expression or declaration of objection, disapproval, or dissent, often in opposition to something a person is
            powerless to prevent or avoid." ~ <Link href="https://www.dictionary.com/browse/protest" target="_blank">dictionary.com</Link>
      </Typography>
      <Typography paragraph='true'>
        In U.S. history, there are a myriad of examples of protests that can be referenced. To list a few:
        </Typography>
      <ul>
        <li>The Boston Tea Party - 1773</li>
        <li>The Revolutionary War - 1775</li>
        <li>The Alabama Bus Boycott - 1955</li>
        <li>The March on Washington D.C. - 1963</li>
        <li>The March on Selma - 1965</li>
        <li>Anti-Vietnam War Protest - 1969</li>
        <li>The Women's March - 2016</li>
        <li>March For Science - 2016</li>
        <li>Black Lives Matter</li>
      </ul>
      <Typography paragraph='true'>
        All of these moments in history are examples of American's protesting; Raising awareness about issues that are important to them and making their voices heard
        about something that they felt was injust.
        </Typography>
      <Typography paragraph='true'>
        In 2016, to raise awareness and protest aggressive law enforcement tactics against African-American communities, Colin Kaepernick decided to kneel on the
        sidelines during the national anthem at the begining of each NFL football game. While most may have been empathetic to what he was protesting, how he was
        protesting raised a lot of questions and received a lot of criticism. Was kneeling disrespectful to our military? Was kneeling disrespectful to our flag?
        Was kneeling disrespectful to our country?
        </Typography>
      <Typography paragraph='true'>
        Those critical of Kaepernick's protest would often say, "That's not the right way to protest." A question often asked in response was, "If that's not the right way,
        then what is the right way for African-American's to protest and be heard?" This project was partially inspired by that last question. What is the right way for African-American's to
        protest and make our voices heard?
        </Typography>
      <Typography paragraph='true'>
        In considering all of the ways that African-American's (and allies) have protested throughout history, one of the peaceful ways that might be overlooked, is protest through art and
        specifically through music. The "We've Been Protesting" project was put together to catalog and visualize various forms of artistic expression in support of the African-American community
        and show the intersection between historical events, the artists of the day and how those events may have inspired their art and music. From old negro spirituals, to jazz, to blues, to rock n roll, to
        rhythm and blues, to hip-hop, you can find artists speaking on the issues relevant to their time and attempting to paint a picture of how they (and the African-American community) may
        view the world and what is going on around them.
        </Typography>
      <Typography paragraph='true'>
        This project is a labor of love, so the project team will continue to devote time to updates and maintenance as we're permitted. The project team is open to suggestions on content to add
        or updates anyone would like to see in the future.
        </Typography>
      <Typography>
        We can be reached at:
        </Typography>
      <ul>
        <li>Twitter: <Link href="https://twitter.com/beenprotesting">@beenprotesting</Link></li>
        <li>Instagram: <Link href="https://www.instagram.com/wevebeenprotesting/">@wevebeenprotesting</Link></li>
        <li>Email: <Link href="mailto:info@wevebeenprotesting.com">info@wevebeenprotesting.com</Link></li>
      </ul>
      <Typography>
        Much love - The We've Been Protesting Project Team! - #wevebeenprotesting
        </Typography>

      <Divider></Divider>

      <Typography style={{ margin: '10px 0' }} variant='h4'>
        Data Deletion
        </Typography>

      <Typography paragraph='true' className={styles.delete_sm}>
        To delete your data simply go to the side menu and select "Delete Account" at the bottom.
        </Typography>

      <Typography paragraph='true' className={styles.delete_md}>
        To delete your data simply go to the nav bar at the top, click on "My Account" then "Delete Account".
        </Typography>
    </Container>
  );
}


export default About;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Modal from "@material-ui/core/Modal";
import Button from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";
import Typography from '@material-ui/core/Typography';
import CardActions from "@material-ui/core/CardActions";
import CardContent from '@material-ui/core/CardContent';
import { noop } from "../utils/utils";

interface Props {
    title: string;
    message?: string;
    actions: {
        title: string,
        handler?: () => void,
        role?: "destructive" | "cancel" | ""
    }[]
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: "30px",
        textOverflow: 'wrap',
        textAlign: 'center'
    },
    message: {
        fontSize: 18,
        textAlign: 'center'
    },
    card: {
        position: 'relative',
        margin: "0 auto",
        marginTop: "10em",
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        paddingTop: '3em',
        minWidth: "380px",
        minHeight: "230px",
        width: "70vw",
        [theme.breakpoints.up("md")]: {
            paddingTop: '1em',
            height: 'auto',
            width: "40vw",
        }
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            padding: "10px 80px"
        }
    },
    cardActions: {
        marginTop: "5%",
        [theme.breakpoints.up("md")]: {
            padding: "20px 0"
        }
    },
}))

export const ConfirmContext = React.createContext<{
    show: (props: Props) => void
}>(null);

const ConfirmContextProvider = ({
    children
}) => {


    const classes = useStyles();
    const [data, setData] = useState<Props>(null);

    const show = useCallback((props: Props) => {
        props.actions.push({title: "Cancel", role: 'cancel'})
        setData(props);
    }, [])

    const close = useCallback(() => {
        setData(null)
    }, [])

    const handleClick = (cb) => {
        return () => {

            close();

            if (cb) cb();
        }
    }

    const actions = useMemo(() => {
        if (data) {
            return data.actions.sort(({ role: arole = "" }, { role: brole = "" }) => brole.localeCompare(arole)).map(({
                title,
                handler,
                role
            }) => (
                <Button
                    variant="contained"
                    color={role === "destructive" ? "secondary" : "primary"}
                    onClick={handleClick(handler)}
                >
                    {title}
                </Button>
            ))
        }
    }, [data])


    return (
        <ConfirmContext.Provider value={{ show }}>
            {children}
            <Modal
                open={!!data}
                onClose={close}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {data ?
                    <Card className={classes.card}>
                        <Typography component="h2" className={classes.title}>
                            {data.title}
                        </Typography>

                        <CardContent className={classes.cardContent}>

                            {data.message ?
                                <Typography component="h2" className={classes.message}>
                                    {data.message}
                                </Typography>
                                :
                                null
                            }

                        </CardContent>

                        <CardActions className={classes.cardActions}>
                            {actions}
                        </CardActions>
                    </Card>
                    : null
                }

            </Modal>
        </ConfirmContext.Provider>
    )
}


export default ConfirmContextProvider